<template>
  <div class="px-10">
    <div class="text-h4 primary--text mb-5">
      {{ isUpdate ? 'แก้ไข' : 'สร้าง' }}รายละเอียดคูปองส่วนลด
    </div>

    <v-row justify="center">
      <v-col cols="1">
        <div>รหัสชื่อคูปอง</div>
        <div>รายละเอียด</div>
      </v-col>
      <v-col cols="3">
        <v-text-field
          name="code"
          v-model="form.code"
          outlined dense
          :error-messages="$v.form.code.$error ? $error_text : ''" 
        />
        <v-textarea
          name="detail"
          v-model="form.detail"
          outlined dense
        />
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="2">
        <div>วันหมดอายุ</div>
        <div>อายุการใช้งาน</div>
        <div>จำนวนคูปองสูงสุด</div>
      </v-col>
      <v-col cols="3">
        <DatePicker
          :value="filter.end_date"
          @onChange="val => { filter.end_date = val; getData(); }"
          outlined
          dense
        />
        <v-text-field
          name="lifetime"
          v-model="form.lifetime"
          outlined
          dense
        />
        <v-text-field
          name="limit"
          v-model="form.limit"
          outlined
          dense
        />
      </v-col>
      <v-col cols="1">
        <div> </div>
        <div>เดือน</div>
        <div>คน</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
        <div>รายละเอียด</div>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="form.discount"
      :loading="loading"
      sort-by="id"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading-text="$table_loading_text"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.index`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.category`]="{ item }">
        {{ item.category == '0' ? 'หลัก' : '' }}
        {{ item.category == '1' ? 'เสริม' : '' }}
      </template>
      <template v-slot:[`item.product_type`]="{ item }">
        {{ item.product_type == '0' ? 'รายวัน' : '' }}
        {{ item.product_type == '1' ? 'รายเดือน' : '' }}
        {{ item.product_type == '2' ? 'รายปี' : '' }}
      </template>
      <template v-slot:[`item.discount`]="{ item }">
        <v-text-field class="py-2" hide-details outlined dense v-model="item.discount"/>
      </template>
    </v-data-table>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ปิด</v-btn
        >
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
          >บันทึกข้อมูล</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import { required } from 'vuelidate/lib/validators';
import * as moment from "moment";

export default {
  components: {
    DatePicker
  },
  validations: {
    form: {
      code: { required }
    }
  },
  data: () => ({
    form: {
      _id: "",
      code: "",
      detail: "",
      end_date: moment().add(+30, 'days').format("YYYY-MM-DD"),
      is_available: false,
      lifetime: 0,
      limit: 0,
      remain: 0,
      start_date: moment().add(-1, 'days').format("YYYY-MM-DD"),
      discount: [],
    },
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: 'ลำดับ',
        align: 'start',
        sortable: false,
        value: 'index',
        filterable: false,
      },
      { text: 'แพ็กเกจ', value: 'name' },
      { text: 'หมวดหมู่', value: 'category', filterable: false },
      { text: 'รูปแบบ', value: 'product_type', filterable: false },
      { text: 'ลดกี่%', value: 'discount', filterable: false }
    ],
    dialogUpdate: false,
    dialogData: null,
    list: {
      categories: [
        { category: 0, name: 'หลัก' },
        { category: 1, name: 'ทั่วไป' },
      ]
    },
    filter: {
      item_per_page: 20,
      page: 1,
      start_with: '',
      end_date: moment().add(+30, 'days').format("YYYY-MM-DD")
    }
  }),

  created() {
    if (this.isUpdate) 
    this.getData();
    this.loading = false;
  },

  methods: {
    async getData() {

      if (this.$route.params.id) {
        let body = {
          token: this.$jwt.sign({ coupon_id:this.$route.params.id }, this.$privateKey, { noTimestamp: true })
        };
        await this.$axios.post(`${this.$baseUrl}/backend/coupon/get_by_id`, body)
          .then(async res => {
            this.form = res.result;
            console.log(this.form)
          })
          .catch(err => {
            console.log('err', err);
          });
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
        
      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let subPath = 'create_coupon';
            if (this.isUpdate) {
              subPath = 'edit_coupon';
              this.form = { coupon_id: this.form._id, ...this.form };
            }
            console.log('this.isUpdate =>> ', this.form)
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true
              })
            };
            await this.$axios.post(`${this.$baseUrl}/backend/coupon/${subPath}`,  body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                if (!this.isUpdate) this.$router.push({ name: 'backoffice-coupon' });
              })
              .catch(err => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
          
        }
      );
    },
    close() {
      this.$router.push({ name: 'backoffice-coupon' });
    },
    onChangePage(page) {
      this.page = page
      this.getData()
    },
    onChangeitemsPerPage(number) {
      this.itemsPerPage = number
      this.onChangePage(1);
    }
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
  },
};
</script>
